import React from 'react';

function Standings({ standings, homeTeam, awayTeam }) {
    if (!standings || standings.length === 0) {
        return <div>No standings data available.</div>;
    }

    const renderTable = (table, index) => (
        <div key={index} className="standings-table-container">
            {standings.length > 1 && <h3 className="standings-group-name">{`${table[0].group}`}</h3>}
            <table className="standings-table">
                <thead>
                    <tr>
                        <th title="Position">Pos</th>
                        <th title="Team Name">Team</th>
                        <th title="Matches Played">P</th>
                        <th title="Matches Won">W</th>
                        <th title="Matches Drawn">D</th>
                        <th title="Matches Lost">L</th>
                        <th title="Goals For">GF</th>
                        <th title="Goals Against">GA</th>
                        <th title="Goal Difference">GD</th>
                        <th title="Points">Pts</th>
                    </tr>
                </thead>
                <tbody>
                    {table.map((team) => (
                        <tr key={team.team.id} className={
                            team.team.id === parseInt(homeTeam) || team.team.id === parseInt(awayTeam)
                                ? 'highlighted'
                                : ''
                        }>
                            <td>{team.rank}</td>
                            <td>{team.team.name}</td>
                            <td>{team.all.played}</td>
                            <td>{team.all.win}</td>
                            <td>{team.all.draw}</td>
                            <td>{team.all.lose}</td>
                            <td>{team.all.goals.for}</td>
                            <td>{team.all.goals.against}</td>
                            <td>{team.goalsDiff}</td>
                            <td>{team.points}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="standings-wrapper">
            {Array.isArray(standings[0]) 
                ? standings.map((table, index) => renderTable(table, index))
                : renderTable(standings, 0)
            }
        </div>
    );
}

export default Standings;
