import React from 'react';
import H2H from "./H2H";

function Drawer({ isDrawerOpen, selectedTeams, closeDrawer, tabToOpen='h2h' }) {
    return (
        <>
            {isDrawerOpen && ((selectedTeams.home && selectedTeams.away) || (selectedTeams.leagueId && selectedTeams.round && selectedTeams.season)) && (
                <H2H key={`${selectedTeams.home}-${selectedTeams.away}`}
                     isOpen={isDrawerOpen}
                     onClose={closeDrawer}
                     selectedTeams={selectedTeams}
                     tabToOpen={tabToOpen}
                />
            )}
        </>
    );
}

export default Drawer;
