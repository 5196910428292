import React, { useEffect, useState } from 'react';
import axios from "axios";
import MatchRow from "./MatchRow";
import LastMatches from "./LastMatches";
import Standings from "./Standings";
import moment from 'moment';
import Select from 'react-select';

function H2H({ isOpen, onClose, selectedTeams, tabToOpen='h2h' }) {
    const [activeTab, setActiveTab] = useState(tabToOpen);
    const homeTeam = selectedTeams.home;
    const awayTeam = selectedTeams.away;
    const { leagueId, round, season, homeName, awayName } = selectedTeams;
    const [h2hMatches, setH2hMatches] = useState([]);
    const [lastHomeMatches, setLastHomeMatches] = useState([]);
    const [lastAwayMatches, setLastAwayMatches] = useState([]);
    const [standings, setStandings] = useState([]);
    const [roundMatches, setRoundMatches] = useState([]);
    const [showOnlyRounds, setShowOnlyRounds] = useState(false);
    const [rounds, setRounds] = useState([]);
    const [selectedRound, setSelectedRound] = useState(null);
    const [previouslyFetchedRound, setPreviouslyFetchedRound] = useState(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        setShowOnlyRounds(selectedTeams.home === '' && selectedTeams.away === '');

        const toDate = moment().add(1,'days').format('YYYY-MM-DD');
        // Simulate fetching matches for the selected date
        const fetchH2H = (homeTeam, awayTeam) => {
            const statuses = 'FT-AET-PEN-NS'
            axios.get(`https://v3.football.api-sports.io/fixtures/headtohead?h2h=${homeTeam}-${awayTeam}&from=2000-01-01&to=${toDate}&status=${statuses}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response && response.data.response.length) {
                        //console.log(response.data.response);

                        setH2hMatches(response.data.response.sort((a, b) => {
                            return new Date(b.fixture.date) - new Date(a.fixture.date);
                        }));
                    }
                })
                .catch(error => {
                    console.error('Error fetching matches:', error);
                });
        };

        const getRoundMatches = (round) => {
            // Only fetch if the round has changed
            if (round !== previouslyFetchedRound) {
                axios.get(`https://v3.football.api-sports.io/fixtures?league=${leagueId}&round=${round}&season=${season}`, {
                    headers: {
                        'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                    }
                })
                    .then(response => {
                        if (response && response.data && response.data.response && response.data.response.length) {
                            setPreviouslyFetchedRound(round);
                            setRoundMatches(response.data.response.sort((a, b) => {
                                return new Date(b.fixture.date) - new Date(a.fixture.date);
                            }));
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching round matches:', error);
                    });
            }
        }

        const getRoundsForLeague = () => {
            axios.get(`https://v3.football.api-sports.io/fixtures/rounds?league=${leagueId}&season=${season}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response && response.data.response.length) {
                        const roundOptions = response.data.response.map(round => ({
                            value: round,
                            label: round
                        }));
                        setRounds(roundOptions);

                        // Set the current round as selected
                        const currentRoundOption = roundOptions.find(option => option.value === round);
                        setSelectedRound(currentRoundOption);

                    }
                })
                .catch(error => {
                    console.error('Error fetching rounds:', error);
                });
        }

        const fetchLastMatches = (teamId) => {
            axios.get(`https://v3.football.api-sports.io/fixtures?team=${teamId}&last=10`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response && response.data.response.length) {
                        const sortedMatches = response.data.response.sort((a, b) => {
                            return new Date(b.fixture.date) - new Date(a.fixture.date);
                        });
                        if (teamId === homeTeam) {
                            setLastHomeMatches(sortedMatches);
                        } else {
                            setLastAwayMatches(sortedMatches);
                        }
                    }
                })
                .catch(error => {
                    console.error('Error fetching last matches:', error);
                });
        };

        const fetchStandings = () => {
            axios.get(`https://v3.football.api-sports.io/standings?league=${leagueId}&season=${season}`, {
                headers: {
                    'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
                }
            })
                .then(response => {
                    if (response && response.data && response.data.response && response.data.response.length) {
                        setStandings(response.data.response[0].league.standings);
                    }
                })
                .catch(error => {
                    console.error('Error fetching standings:', error);
                });
        };
            
        if (activeTab === 'h2h' && !h2hMatches.length && homeTeam && awayTeam) {
            fetchH2H(homeTeam, awayTeam);
        } else if (activeTab === 'lastMatches' && !lastHomeMatches.length && !lastAwayMatches.length) {
            fetchLastMatches(homeTeam);
            fetchLastMatches(awayTeam);
        } else if (activeTab === 'standings' && !standings.length) {
            fetchStandings();
        } else if (activeTab === 'rounds') {
            if (!rounds.length) {
                getRoundsForLeague();
            } else if (selectedRound && selectedRound.value && selectedRound.value !== previouslyFetchedRound) {
                getRoundMatches(selectedRound.value);
            }
        }

    }, [
        activeTab,
        homeTeam,
        awayTeam,
        leagueId,
        round,
        season,
        rounds,
        h2hMatches,
        lastHomeMatches,
        lastAwayMatches,
        standings,
        roundMatches,
        selectedTeams,
        selectedRound,
        previouslyFetchedRound
    ]);

    const handleRoundChange = (selectedOption) => {
        setSelectedRound(selectedOption);
    };

    if (!isOpen) {
        return null;
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className={`drawer ${isOpen ? 'open' : ''}`}>
                <div className="tabs">
                    {showOnlyRounds ? (
                        <button className="active">Rounds</button>
                    ) : (
                        <>
                            <button
                                className={activeTab === 'h2h' ? 'active' : ''}
                                onClick={() => handleTabClick('h2h')}
                            >
                                H2H
                            </button>
                            <button
                                className={activeTab === 'lastMatches' ? 'active' : ''}
                                onClick={() => handleTabClick('lastMatches')}
                            >
                                Last Matches
                            </button>
                            <button
                                className={activeTab === 'standings' ? 'active' : ''}
                                onClick={() => handleTabClick('standings')}
                            >
                                Standings
                            </button>
                            <button
                                className={activeTab === 'rounds' ? 'active' : ''}
                                onClick={() => handleTabClick('rounds')}
                            >
                                Rounds
                            </button>
                        </>
                    )}
                </div>
                <div className="match-list">
                    {!showOnlyRounds && activeTab === 'h2h' && (
                        <>
                            <div className="match-header">
                                <span>Date</span>
                                <span className="team-name">Home</span>
                                <span>Score</span>
                                <span className="team-name">Away</span>
                                <span>HT</span>
                                <span>Status</span>
                            </div>
                            {h2hMatches.map((match) => (
                                <MatchRow key={match.fixture.id}
                                          match={match}
                                          dateFormat="YYYY-MM-DD"
                                          h2h={true}/>
                            ))}
                        </>
                    )}
                    {!showOnlyRounds && activeTab === 'lastMatches' && (
                        <LastMatches
                            homeTeam={homeName}
                            awayTeam={awayName}
                            lastHomeMatches={lastHomeMatches}
                            lastAwayMatches={lastAwayMatches}
                        />
                    )}
                    {!showOnlyRounds && activeTab === 'standings' && (
                        <Standings
                            standings={standings}
                            homeTeam={homeTeam}
                            awayTeam={awayTeam}
                        />
                    )}
                    {(showOnlyRounds || activeTab === 'rounds') && (
                        <>
                            <Select
                                value={selectedRound}
                                onChange={handleRoundChange}
                                options={rounds}
                                className="round-select"
                                placeholder="Select a round"
                                isSearchable={false}
                            />
                            <div className="match-header">
                                <span>Date</span>
                                <span className="team-name">Home</span>
                                <span>Score</span>
                                <span className="team-name">Away</span>
                                <span>HT</span>
                                <span>Status</span>
                            </div>
                            {roundMatches.map((match) => (
                                <MatchRow key={match.fixture.id}
                                          match={match}
                                          dateFormat="YYYY-MM-DD"
                                          h2h={true}/>
                            ))}
                        </>
                    )}
                </div>
            </div>
            {isOpen && <div className="overlay" onClick={onClose}></div>}
        </>
    );
}

export default H2H;
