import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import '../css/cookie-banner.css';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'true', { expires: 365 });
        setShowBanner(false);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div className="cookie-banner">
            <div className="cookie-content">
                <p>
                    We use cookies to improve your onsite experience. By understanding how you use our products, we can improve navigation, build better features, and provide you with more relevant content and marketing messages. For more information about cookies, and to manage your preferences, please see our
                    <a href="/cookie-policy"> Cookie Policy</a>. By continuing to use this site you are agreeing to our use of cookies and personal data in accordance with our
                    <a href="/privacy-policy"> Privacy Policy</a>.
                </p>
                <button onClick={handleAccept} className="accept-button">Accept</button>
            </div>
        </div>
    );
};

export default CookieBanner;
