import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimezoneDropdown from "./TimezoneDropdown";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

function Header({ setPeriod, selectedDate, setSelectedDate, setTimezone, selectedTimezone, setLive, setTimeFilter, activeFilter }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleNavigation = (path, period) => {
        setPeriod(period);
        navigate(path);
    };

    const handleDateChange = (date) => {
        setLive(false);
        setSelectedDate(date);
        navigate('/');
    };

    const handlePrevDay = () => {
        const prevDay = moment(selectedDate).subtract(1, 'day').toDate();
        handleDateChange(prevDay);
    };

    const handleNextDay = () => {
        const nextDay = moment(selectedDate).add(1, 'day').toDate();
        handleDateChange(nextDay);
    };

    const handleFilterClick = (hours) => {
        if (hours === activeFilter) {
            setTimeFilter(null);
        } else {
            setTimeFilter(hours);
        }
    };

    const filters = [
        { label: '1h', hours: 1 },
        { label: '3h', hours: 3 },
        { label: '6h', hours: 6 },
        { label: '12h', hours: 12 },
        { label: '24h', hours: 24 },
    ];

    return (
        <header>
            <nav className="nav-menu">
                <div className="logo-container">
                    <div className="logo-text">SCORETABLE.LIVE</div>
                    <button className="mobile-menu-toggle" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} />
                    </button>
                </div>
                <div className={`nav-menu-items ${mobileMenuOpen ? 'mobile-open' : ''}`}>
                    <div className="menu-section timezone-row">
                        <TimezoneDropdown
                            selectedTimezone={selectedTimezone}
                            onTimezoneChange={setTimezone}
                        />
                    </div>
                    <div className="menu-section period-row">
                        <button onClick={() => handleNavigation('/', 'now')}>Now</button>
                        <button onClick={() => handleNavigation('/', 'yesterday')}>Yesterday</button>
                        <button onClick={() => handleNavigation('/', 'today')}>Today</button>
                        <button onClick={() => handleNavigation('/', 'tomorrow')}>Tomorrow</button>
                    </div>
                    <div className="menu-section date-row">
                        <button onClick={handlePrevDay} className="date-nav-button">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="yyyy-MM-dd"
                            className="date-picker"
                            inputProps={{readOnly: true}}
                            onFocus={e => e.target.blur()}
                        />
                        <button onClick={handleNextDay} className="date-nav-button">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                    <div className="menu-section filter-row">
                        {filters.map((filter) => (
                            <button
                                key={filter.hours}
                                className={`time-filter-button ${activeFilter === filter.hours ? 'active' : ''}`}
                                onClick={() => handleFilterClick(filter.hours)}
                            >
                                {filter.label}
                            </button>
                        ))}
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
