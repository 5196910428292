import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/cookie-policy">Cookie Policy</Link>
                </div>
            </div>
            <p>&copy; 2024 ScoreTable. All rights reserved.</p>
        </footer>
    );
}

export default Footer;
