import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSoccerBall, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MatchStarComponent from './MatchStarComponent';
import MatchStatistics from './MatchStatistics';

function MatchRow({ match, dateFormat, h2h, timezone, setIsDrawerOpen, setSelectedTeams, onStarUpdate, round, season, leagueId, setTabToOpen }) {
    const formattedTime = h2h ? moment(match.fixture.date).format(dateFormat) : moment(match.fixture.date).tz(timezone).format(dateFormat);
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [matchEvents, setMatchEvents] = useState([]);
    const [matchStats, setMatchStats] = useState(null);
    const [activeTab, setActiveTab] = useState('events');
    const [isLoading, setIsLoading] = useState(false);

    const finishedStatuses = ['FT', 'AET', 'PEN', 'ABD', 'HT'];
    const inPlayStatuses = ['1H', '2H', 'ET', 'P', 'BT', 'PEN', 'LIVE'];
    const canceledStatuses = ['PST', 'CANC', 'ABD']
    const notStartedStatuses = ['TBD', 'NS', 'AWD', 'WO'];

    const getWinnerClass = (winner, matchStatus) => {
        if (!finishedStatuses.includes(matchStatus) || matchStatus === 'HT') {
            return '';
        }

        if (winner === null) {
            return 'draw';
        }

        return winner ? 'winner' : 'loser';
    };

    const handleHeadToHeadClick = (e, homeTeam, awayTeam) => {
        e.stopPropagation();
        setIsDrawerOpen(false);
        setSelectedTeams({ 
            home: homeTeam,
            away: awayTeam,
            round: round,
            season: season,
            leagueId: leagueId,
            homeName: homeName,
            awayName: awayName
        });
        setTabToOpen('h2h');
        setIsDrawerOpen(true);
    };

    const renderEvent = (event, isHome) => {
        const eventClass = isHome ? 'home-event' : 'away-event';
        
        const formatTime = (timeObj) => {
            if (!timeObj) return '';
            let timeStr = timeObj.elapsed || '';
            if (timeObj.extra) {
                timeStr += '+' + timeObj.extra;
            }
            return timeStr;
        };

        // Format the event detail based on type
        const formatEventDetail = (event) => {
            switch (event.type) {
                case 'Goal':
                    if (event.detail.includes('Own Goal')) {
                        return `${event.player.name} (OG)`;
                    }
                    return event.player.name + (event.assist.name ? ` (assist by ${event.assist.name})` : '');
                case 'Var':
                    if (event.detail.includes('Goal')) {
                        return `Goal disallowed - ${event.player.name}`;
                    }
                    return event.detail;
                case 'Card':
                    return event.player.name;
                case 'subst':
                    return (
                        <div className="substitution-container">
                            <div className="substitution-player player-in">↑ {event.player.name}</div>
                            <div className="substitution-player player-out">↓ {event.assist.name}</div>
                        </div>
                    );
                default:
                    return event.detail;
            }
        };
        
        return (
            <li className={eventClass}>
                {isHome ? (
                    <>
                        <span className="event-text">
                            {formatEventDetail(event)}
                        </span>
                        <span className="event-time">{formatTime(event.time)}'</span>
                        {renderEventIcon(event)}
                    </>
                ) : (
                    <>
                        {renderEventIcon(event)}
                        <span className="event-time">{formatTime(event.time)}'</span>
                        <span className="event-text">
                            {formatEventDetail(event)}
                        </span>
                    </>
                )}
            </li>
        );
    };

    const renderEventIcon = (event) => {
        switch (event.type) {
            case 'Goal':
                return <FontAwesomeIcon icon={faSoccerBall} className={`goal-icon ${event.detail.includes('Own Goal') ? 'own-goal' : ''}`} />;
            case 'Var':
                if (event.detail.includes('Goal')) {
                    return <FontAwesomeIcon icon={faSoccerBall} className="var-goal-disallowed" />;
                }
                return null;
            case 'Card':
                if (event.detail.includes('Yellow')) {
                    return <FontAwesomeIcon icon={faSquare} className="yellow-card-icon" />;
                } else if (event.detail.includes('Second Yellow')) {
                    return <FontAwesomeIcon icon={faSquare} className="second-yellow-card-icon" />;
                } else {
                    return <FontAwesomeIcon icon={faSquare} className="red-card-icon" />;
                }
            case 'subst':
                return <FontAwesomeIcon icon={faArrowRightArrowLeft} className="substitution-icon" />;
            default:
                return null;
        }
    };

    const homeWinner = match.teams.home.winner;
    const homeName = match.teams.home.name;
    const awayWinner = match.teams.away.winner;
    const awayName = match.teams.away.name;
    const homeHTScore = match.score.halftime.home;
    const awayHTScore = match.score.halftime.away;
    const penaltyHome = match.score.penalty.home;
    const penaltyAway = match.score.penalty.away;
    let matchStatus = match.fixture.status.short;
    const matchStatusLong = match.fixture.status.long;
    const elapsed = match.fixture.status.elapsed;
    const goalsHome = match.goals.home;
    const goalsAway = match.goals.away;
    const homeTeam = match.teams.home.id;
    const awayTeam = match.teams.away.id;

    const halfTimeDraw = homeHTScore === awayHTScore;
    const homeHTWinner = halfTimeDraw ? null : homeHTScore > awayHTScore;
    const awayHTWinner = halfTimeDraw ? null : awayHTScore > homeHTScore;

    const isOngoing = !finishedStatuses.includes(matchStatus) && inPlayStatuses.includes(matchStatus);

    if (matchStatus === 'P') {
        matchStatus = 'Penalties';
    }

    if (matchStatus === 'BT') {
        matchStatus = 'Break';
    }

    const toggleMatchDetails = (matchId) => {
        if (notStartedStatuses.includes(matchStatus) || canceledStatuses.includes(matchStatus)) {
            return;
        }

        if (isDetailsVisible) {
            setIsDetailsVisible(false);
        } else {
            setIsDetailsVisible(true);
            fetchMatchEvents(matchId);
        }
    };

    const fetchMatchEvents = (matchId) => {
        setIsLoading(true);
        axios.get(`https://v3.football.api-sports.io/fixtures/events?fixture=${matchId}`, {
            headers: {
                'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
            }
        })
            .then(response => {
                if (response && response.data && response.data.response) {
                    setMatchEvents(response.data.response);
                }
            })
            .catch(error => {
                console.error('Error fetching match events:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchMatchStats = (matchId) => {
        setIsLoading(true);
        axios.get(`https://v3.football.api-sports.io/fixtures/statistics?fixture=${matchId}`, {
            headers: {
                'x-rapidapi-key': process.env.REACT_APP_API_FOOTBALL_KEY
            }
        })
            .then(response => {
                if (response && response.data && response.data.response) {
                    setMatchStats(response.data.response);
                }
            })
            .catch(error => {
                console.error('Error fetching match statistics:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'events') {
            fetchMatchEvents(match.fixture.id);
        } else if (tab === 'statistics') {
            fetchMatchStats(match.fixture.id);
        }
    };

    useEffect(() => {
        if (isDetailsVisible && activeTab === 'statistics' && !matchStats) {
            fetchMatchStats(match.fixture.id);
        }
    }, [isDetailsVisible, activeTab, matchStats, match.fixture.id]);

    // Add handler for time click
    const handleTimeClick = (e) => {
        e.stopPropagation(); // Prevent match details from opening
        // Find and click the star icon
        const starIcon = e.currentTarget.querySelector('.match-star-icon');
        if (starIcon) {
            starIcon.click();
        }
    };

    return (
        <>
            <div
                className={`match-row ${canceledStatuses.includes(matchStatus) ? 'canceled' : ''}`}
                onClick={() => toggleMatchDetails(match.fixture.id)}
            >
                <span 
                    className={`match-time-container ${h2h ? "match-date" : ""}`}
                    onClick={!h2h ? handleTimeClick : undefined}
                    title={!h2h ? "Click to favorite this match" : ""}
                >
                    {!h2h && (
                        <MatchStarComponent
                            match={match}
                            onStarUpdate={onStarUpdate}
                        />
                    )}
                    {formattedTime}
                </span>
                <span className={`${getWinnerClass(homeWinner, matchStatus)} team-name`}>
                    {homeName}
                </span>
                <span
                    onClick={h2h ? () => {} : (e) => handleHeadToHeadClick(e, homeTeam, awayTeam)}
                    tabIndex="0"
                    className={`main-score ${!h2h ? 'clickable-score' : ''}`}
                    title={!h2h ? "Click to see head-to-head statistics" : ""}
                >
                    <span>
                        <span className={`${getWinnerClass(homeWinner, matchStatus)}`}>
                            {goalsHome === null ? "–" : goalsHome}
                        </span>
                        :
                        <span className={`${getWinnerClass(awayWinner, matchStatus)}`}>
                            {goalsAway === null ? "–" : goalsAway}
                        </span>
                    </span>
                    {penaltyHome !== null && penaltyAway !== null && (
                        <p className="penalty-score">
                            (<label className={`${getWinnerClass(homeWinner, matchStatus)}`}>
                                {penaltyHome}
                            </label> : <label className={`${getWinnerClass(awayWinner, matchStatus)}`}>
                                {penaltyAway}
                            </label>)
                        </p>
                    )}
                </span>
                <span className={`${getWinnerClass(awayWinner, matchStatus)} team-name`}>
                    {awayName}
                </span>
                <span>
                    <span className={`${getWinnerClass(homeHTWinner, matchStatus)}`}>
                        {homeHTScore === null ? "–" : homeHTScore}
                    </span>
                    :
                    <span className={`${getWinnerClass(awayHTWinner, matchStatus)}`}>
                        {awayHTScore === null ? "–" : awayHTScore}
                    </span>
                </span>
                <span className={`${isOngoing ? 'ongoing' : ''} ${matchStatus === "HT" ? 'ht-status' : ''}`}>
                    {(!inPlayStatuses.includes(matchStatus) || matchStatusLong === 'Match Finished' || matchStatus === 'P' || matchStatus === 'BT') ? matchStatus : ''} {elapsed && !finishedStatuses.includes(matchStatus) && !h2h ? `${elapsed}'` : ''}
                </span>
            </div>
            {isDetailsVisible && (
                <div className="match-details">
                    <div className="tabs">
                        <button 
                            className={activeTab === 'events' ? 'active' : ''} 
                            onClick={() => handleTabClick('events')}
                        >
                            Events
                        </button>
                        <button 
                            className={activeTab === 'statistics' ? 'active' : ''} 
                            onClick={() => handleTabClick('statistics')}
                        >
                            Statistics
                        </button>
                    </div>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            {activeTab === 'events' && (
                                matchEvents && matchEvents.length > 0 ? (
                                    <ul>
                                        {matchEvents.map((event, index) => (
                                            <React.Fragment key={`${event.time.elapsed}-${event.type}-${index}`}>
                                                {renderEvent(event, event.team.name === homeName)}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No events available for this match.</p>
                                )
                            )}
                            {activeTab === 'statistics' && (
                                matchStats ? (
                                    <MatchStatistics stats={matchStats} homeName={homeName} awayName={awayName} />
                                ) : (
                                    <p>No statistics available for this match.</p>
                                )
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default MatchRow;
