import React from 'react';
import MatchRow from "./MatchRow";

function LastMatches({ homeTeam, awayTeam, lastHomeMatches, lastAwayMatches }) {
    return (
        <>
            <h3>{homeTeam}</h3>
            <div className="match-header">
                <span>Date</span>
                <span className="team-name">Home</span>
                <span>Score</span>
                <span className="team-name">Away</span>
                <span>HT</span>
                <span>Status</span>
            </div>
            {lastHomeMatches.map((match) => (
                <MatchRow key={match.fixture.id}
                          match={match}
                          dateFormat="YYYY-MM-DD"
                          h2h={true}/>
            ))}
            <h3>{awayTeam}</h3>
            <div className="match-header">
                <span>Date</span>
                <span className="team-name">Home</span>
                <span>Score</span>
                <span className="team-name">Away</span>
                <span>HT</span>
                <span>Status</span>
            </div>
            {lastAwayMatches.map((match) => (
                <MatchRow key={match.fixture.id}
                          match={match}
                          dateFormat="YYYY-MM-DD"
                          h2h={true}/>
            ))}
        </>
    );
}

export default LastMatches;