import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faFutbol, 
    faBullseye, 
    faShieldHalved, 
    faSquare, 
    faChartPie,
    faFlag,
    faPersonRunning,
    faTriangleExclamation,
    faArrowsLeftRight,
    faHandsHolding,
    faSquareFull
} from '@fortawesome/free-solid-svg-icons';

function MatchStatistics({ stats, homeName, awayName }) {
    if (!stats || stats.length < 2) return null;

    // Get the stats arrays
    let homeStats = stats[0].statistics;
    let awayStats = stats[1].statistics;

    // Find the ball possession index
    const possessionIndex = homeStats.findIndex(stat => stat.type === 'Ball Possession');

    // If ball possession exists, move it to the front
    if (possessionIndex > -1) {
        // Move in home stats
        const homePossession = homeStats.splice(possessionIndex, 1)[0];
        homeStats.unshift(homePossession);
        
        // Move in away stats
        const awayPossession = awayStats.splice(possessionIndex, 1)[0];
        awayStats.unshift(awayPossession);
    }

    const getStatIcon = (type) => {
        switch (type.toLowerCase()) {
            case 'ball possession':
                return <FontAwesomeIcon icon={faChartPie} className="stat-icon" />;
            case 'shots on goal':
                return <FontAwesomeIcon icon={faBullseye} className="stat-icon" />;
            case 'shots off goal':
                return <FontAwesomeIcon icon={faSquare} className="stat-icon" />;
            case 'total shots':
                return <FontAwesomeIcon icon={faFutbol} className="stat-icon" />;
            case 'blocked shots':
                return <FontAwesomeIcon icon={faShieldHalved} className="stat-icon" />;
            case 'corner kicks':
                return <FontAwesomeIcon icon={faFlag} className="stat-icon" />;
            case 'offsides':
                return <FontAwesomeIcon icon={faPersonRunning} className="stat-icon" />;
            case 'fouls':
                return <FontAwesomeIcon icon={faTriangleExclamation} className="stat-icon" />;
            case 'yellow cards':
                return <FontAwesomeIcon icon={faSquareFull} className="stat-icon yellow-card-stat" />;
            case 'red cards':
                return <FontAwesomeIcon icon={faSquareFull} className="stat-icon red-card-stat" />;
            case 'goalkeeper saves':
                return <FontAwesomeIcon icon={faHandsHolding} className="stat-icon" />;
            case 'passes':
            case 'total passes':
            case 'passes accurate':
            case 'passes %':
                return <FontAwesomeIcon icon={faArrowsLeftRight} className="stat-icon" />;
            default:
                return null;
        }
    };

    const formatStatType = (type) => {
        switch (type) {
            case 'expected_goals':
                return (
                    <>
                        <FontAwesomeIcon icon={faFutbol} className="stat-icon" />
                        <span>Expected Goals (xG)</span>
                    </>
                );
            case 'goals_prevented':
                return (
                    <>
                        <FontAwesomeIcon icon={faShieldHalved} className="stat-icon" />
                        <span>Goals Prevented</span>
                    </>
                );
            default:
                const formattedType = type.charAt(0).toUpperCase() + type.slice(1).replace(/_/g, ' ');
                return (
                    <>
                        {getStatIcon(formattedType)}
                        <span>{formattedType}</span>
                    </>
                );
        }
    };

    const calculatePercentage = (homeValue, awayValue, type) => {
        if (type === 'Passes %' || type === 'Ball Possession') {
            return {
                home: Number(String(homeValue).replace('%', '')),
                away: Number(String(awayValue).replace('%', ''))
            };
        }

        // For other stats, calculate the relative percentages
        const total = Number(homeValue) + Number(awayValue);
        if (total === 0) return { home: 50, away: 50 };
        const homePercent = (Number(homeValue) / total) * 100;
        return { 
            home: homePercent,
            away: 100 - homePercent
        };
    };

    const formatStatValue = (value, type) => {
        if (type === 'expected_goals' || type === 'goals_prevented') {
            return value ? Number(value).toFixed(2) : '0.00';
        }
        if (type === 'ball_possession' || type === 'passes_accurate_percent') {
            return `${value}%`;
        }
        return value || '0';
    };

    return (
        <div className="match-statistics">
            <table className="stats-table">
                <tbody>
                    {homeStats.map((stat, index) => {
                        const percentages = calculatePercentage(
                            stat.value, 
                            awayStats[index].value,
                            stat.type
                        );
                        
                        // For percentage stats, use a fixed total width of 100%
                        const isPercentageStat = stat.type === 'ball_possession' || stat.type === 'passes_accurate_percent';
                        const barStyles = {
                            home: { width: isPercentageStat ? `${percentages.home}%` : `${percentages.home}%` },
                            away: { width: isPercentageStat ? `${percentages.away}%` : `${percentages.away}%` }
                        };
                        
                        return (
                            <tr key={index}>
                                <td className="home-stat">{formatStatValue(stat.value, stat.type)}</td>
                                <td className="stat-bar-container">
                                    <div className="stat-type">
                                        {formatStatType(stat.type)}
                                    </div>
                                    <div className="stat-bars">
                                        <div 
                                            className="home-bar"
                                            style={barStyles.home}
                                        />
                                        <div 
                                            className="away-bar"
                                            style={barStyles.away}
                                        />
                                    </div>
                                </td>
                                <td className="away-stat">{formatStatValue(awayStats[index].value, awayStats[index].type)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default MatchStatistics;
