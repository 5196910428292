import React from 'react';

const CookiePolicy = () => {
    return (
        <div className="website-policy">
            <h1>Cookie Policy</h1>
            <p><strong>Effective Date:</strong> 10 August 2024</p>
            <p>Our website, ScoreTable, located at <a href="https://scoretable.live">https://scoretable.live</a>, uses
                cookies to differentiate you from other users and enhance your experience while browsing. Cookies also
                help us to improve our website's performance.</p>

            <p>If you wish to remove or block cookies from your device, you can do so at any time (instructions provided
                below).</p>

            <h2>What Are Cookies?</h2>
            <p>Cookies are small text files stored on your device's browser or hard drive. These files allow us to
                recognize your device and collect information.</p>

            <h2>Types of Cookies We Use:</h2>
            <ul>
                <li><strong>Strictly Necessary Cookies:</strong> Essential for site functionality, such as logging in or
                    using e-billing services. Blocking these may impact the site’s performance.
                </li>
                <li><strong>Analytical/Performance Cookies:</strong> Help us understand how users interact with our
                    site, which pages are popular, and how visitors navigate. This data assists us in improving our
                    site.
                </li>
                <li><strong>Functionality Cookies:</strong> Remember your preferences and customize the site for you,
                    such as your language settings or region.
                </li>
                <li><strong>Targeting Cookies:</strong> Track your visits and interactions on our site, enabling us to
                    provide relevant advertisements. We may share this information with third parties for advertising
                    purposes.
                </li>
                <li><strong>Social Media Cookies:</strong> Allow easy sharing of content on social media platforms like
                    Facebook and Twitter. These cookies are set by the respective social media platforms.
                </li>
            </ul>

            <h2>Managing Cookies</h2>
            <p>You can block cookies via your browser settings. However, blocking all cookies may result in limited
                functionality on our site. For example, some third-party services embedded in our site, like YouTube
                videos, may not function properly without cookies.</p>
            <p>For more information on managing or deleting cookies, visit <a
                href="https://www.aboutcookies.org.uk">https://www.aboutcookies.org.uk</a>.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions or need more details about our use of cookies, please contact us at contact@scoretable.live.</p>

            <h2>Changes to This Cookie Policy</h2>
            <p>Any updates to our cookie policy will be posted on this page, and we will notify you of significant
                changes through our cookie banner. Also we will notify you of any changes by updating the
                "Effective Date" at the top of this Cookie Policy. You are encouraged to periodically
                review this Cookie Policy to stay informed of updates.</p>
        </div>
    );
};

export default CookiePolicy;
