import React, {useState} from 'react';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as filledStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

const MatchStarComponent = ({ match, onStarUpdate }) => {
    const matchId = parseInt(match.fixture.id, 10);

    const [isStarred, setIsStarred] = useState(isMatchStarred());

    function starMatch(e) {
        e.stopPropagation();
        const favouriteMatches = getStarredMatches();
        if(!favouriteMatches.includes(matchId)) {
            favouriteMatches.push(matchId);
            Cookies.set('starred_matches', favouriteMatches.join(','), { expires: 365 });
            setIsStarred(true);
            onStarUpdate();
        }
    }

    function unstarMatch(e) {
        e.stopPropagation();
        const favouriteMatches = getStarredMatches();
        if(favouriteMatches.includes(matchId)) {
            favouriteMatches.splice(favouriteMatches.indexOf(matchId), 1);
            Cookies.set('starred_matches', favouriteMatches.join(','), { expires: 365 });
            setIsStarred(false);
            onStarUpdate();
        }
    }

    function isMatchStarred() {
        return getStarredMatches().includes(matchId);
    }

    return (
        <span className="starIcon match-star-icon" onClick={isStarred ? (e) => unstarMatch(e) : (e) => starMatch(e)} title="Favourite">
            <FontAwesomeIcon icon={isStarred ? filledStar : emptyStar} />
        </span>
    );
};

export default MatchStarComponent;

export function getStarredMatches() {
    return (Cookies.get('starred_matches') || "").split(',').filter(id => id).map(id => parseInt(id, 10));
}
