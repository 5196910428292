import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

const PrevRoundComponent = ({ leagueId, round, season, setIsDrawerOpen, setSelectedTeams, setTabToOpen }) => {
    leagueId = parseInt(leagueId, 10);

    function showPrevRoundMatches() {
        setIsDrawerOpen(false);
        setSelectedTeams({ 
            home: '', 
            away: '',
            leagueId: leagueId,
            round: round,
            season: season
        });
        setTabToOpen('rounds');
        setIsDrawerOpen(true);
    }

    return (
        <span className="starIcon">
            <FontAwesomeIcon icon={faHistory} onClick={showPrevRoundMatches} title="Rounds"/>
        </span>
    );
};

export default PrevRoundComponent;
