import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="website-policy">
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> 10 August 2024</p>
            <p>Welcome to ScoreTable ("we," "our," or "us"). This Privacy Policy explains how we collect, use, disclose,
                and safeguard your information when you visit our website <a
                    href="https://scoretable.live">https://scoretable.live</a>, including any other media form, media
                channel, mobile website, or mobile application related or connected thereto (collectively, the "Site").
                Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy,
                please do not access the Site.</p>

            <h2>1. Information We Collect</h2>
            <p>We may collect information about you in a variety of ways. The information we may collect on the Site
                includes:</p>
            <ul>
                <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, email
                    address, and telephone number, that you voluntarily give to us when you register with the Site,
                    express interest in obtaining information about us or our products and services, or when you
                    participate in activities on the Site (such as subscribing to newsletters or entering contests).
                </li>
                <li><strong>Derivative Data:</strong> Information our servers automatically collect when you access the
                    Site, such as your IP address, your browser type, your operating system, your access times, and the
                    pages you have viewed directly before and after accessing the Site.
                </li>
                <li><strong>Mobile Device Data:</strong> Device information, such as your mobile device ID, model, and
                    manufacturer, and information about the location of your device, if you access the Site from a
                    mobile device.
                </li>
                <li><strong>Third-Party Data:</strong> Information from third parties, such as personal information or
                    network friends, if you connect your account to the third party and grant the Site permission to
                    access this information.
                </li>
                <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies
                    to track the activity on our Site and hold certain information. Cookies are files with a small
                    amount of data that may include an anonymous unique identifier. You can instruct your browser to
                    refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
                    cookies, you may not be able to use some portions of our Site.
                </li>
            </ul>

            <h2>2. Use of Your Information</h2>
            <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized
                experience. Specifically, we may use the information collected about you via the Site to:</p>
            <ul>
                <li>Create and manage your account.</li>
                <li>Send you an email regarding your account or order.</li>
                <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
                <li>Email you regarding your account or order.</li>
                <li>Improve the Site and your experience.</li>
                <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
            </ul>

            <h2>3. Disclosure of Your Information</h2>
            <p>We may share information we have collected about you in certain situations. Your information may be
                disclosed as follows:</p>
            <ul>
                <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is
                    necessary to respond to legal process, to investigate or remedy potential violations of our
                    policies, or to protect the rights, property, and safety of others, we may share your information as
                    permitted or required by any applicable law, rule, or regulation.
                </li>
                <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties
                    that perform services for us or on our behalf, including payment processing, data analysis, email
                    delivery, hosting services, customer service, and marketing assistance.
                </li>
                <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with,
                    or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                    portion of our business to another company.
                </li>
                <li><strong>Advertisers:</strong> We may use third-party advertising companies to serve ads when you
                    visit the Site. These companies may use information about your visits to the Site and other websites
                    that are contained in web cookies to provide advertisements about goods and services of interest to
                    you.
                </li>
                <li><strong>Data Transfers:</strong> Your information, including Personal Data, may be transferred
                    to—and maintained on—computers located outside of your state, province, country, or other
                    governmental jurisdiction where the data protection laws may differ from those from your
                    jurisdiction. We will take all steps reasonably necessary to ensure that your data is treated
                    securely and in accordance with this Privacy Policy.
                </li>
                <li><strong>Third-Party Links:</strong> The Site may contain links to third-party websites and services
                    that are not owned or controlled by us. We have no control over, and assume no responsibility for,
                    the content, privacy policies, or practices of any third-party websites or services. We strongly
                    advise you to review the privacy policy of every site you visit.
                </li>
            </ul>

            <h2>4. Data Retention</h2>
            <p>We will retain your personal information only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use your personal information to the extent necessary to comply
                with our legal obligations (for example, if we are required to retain your data to comply with
                applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>

            <h2>5. Security of Your Information</h2>
            <p>We use administrative, technical, and physical security measures to help protect your personal
                information. While we have taken reasonable steps to secure the personal information you provide to us,
                please be aware that despite our efforts, no security measures are perfect or impenetrable, and no
                method of data transmission can be guaranteed against any interception or other type of misuse.</p>

            <h2>6. Policy for Children</h2>
            <p>We do not knowingly solicit information from or market to children under the age of 13. If we learn that
                we have collected information from a child under age 13 without verification of parental consent, we
                will delete that information as quickly as possible. If you believe we might have any information from
                or about a child under 13, please contact us at contact@scoretable.live.</p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our
                practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by
                updating the "Effective Date" at the top of this Privacy Policy. You are encouraged to periodically
                review this Privacy Policy to stay informed of updates.</p>

            <h2>8. Contact Us</h2>
            <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
            <p>
                ScoreTable<br/>
                contact@scoretable.live<br/>
            </p>
        </div>
    );
};

export default PrivacyPolicy;
